import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RoutePageData } from 'router/shared/models';
import { Icon, LoadingDots, usePageContext } from 'components';
import { useCheckSummary } from './context';
import { createCluster, getDashboard } from './shared/requests';
import { useParams } from 'react-router-dom';
import {
  ApiError,
  ClusterCreateRequest,
  ReportStatus,
} from 'shared/api/client';
import { useLoader } from 'hooks';
import { getDashboardDataByClusters, getDashboardTiles } from './shared/utils';
import { DashboardCharts, DashboardView } from './dashboard-view';
import { CheckSummaryClusters } from 'pages/check-summary';
import { ErrorPage } from 'pages/error-page';
import { FailedStatusBy } from './dashboard-view/failed-status-by-severity.chart';
import { ClusterReportingStatus } from './dashboard-view/cluster-reporting-status.chart';
import { SummaryCluster, TileID } from './shared/models';
import { FilterMeta } from 'components/filter-tags';
import { Calendar } from 'primereact/calendar';
import styles from './styles.module.scss';
import { getDate } from 'components/utils';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { debounce } from 'lodash'; // Make sure to install lodash
import { ProgressSpinner } from 'primereact/progressspinner';
import { useToast } from 'shared/providers/toast/context';

interface StateProps {}

const Dashboard = () => {
  const { state, setState, error404 } = usePageContext<StateProps>();
  const paramsUrl = useParams();
  const { date: executionDate, clusters, setClusters } = useCheckSummary();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataRequest = useCallback(
    getDashboard(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );

  const fetchDataRequestErrorHandler = useCallback(
    (e: ApiError) => {
      if (e.status === 404) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        error404();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsUrl],
  );

  const [filters, setFilters] = React.useState<FilterMeta>({});

  const { loadedData, isPending } = useLoader(
    fetchDataRequest,
    fetchDataRequestErrorHandler,
  );

  useEffect(() => {
    if (loadedData && !isPending) {
      setClusters(loadedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedData, isPending]);

  const allData = React.useMemo(
    () => getDashboardDataByClusters(clusters || {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusters],
  );
  const showSkeleton = Object.keys(clusters || {}).length === 0 && isPending;

  const onClickTileHandler = useCallback((tile: TileID) => {
    switch (tile) {
      case 'total': {
        setFilters({});
        break;
      }
      case 'healthy': {
        setFilters({ isHealthy: { value: [true] } });
        break;
      }
      case 'unHealthy': {
        setFilters({ isHealthy: { value: [false] } });
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  const tiles = useMemo(
    () => getDashboardTiles({ ...allData, onClick: onClickTileHandler }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allData, onClickTileHandler],
  );

  const onClearHandler = (key: keyof SummaryCluster, value: string) => {
    setFilters((prev) => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });
  };

  const onClickFailedStatusBySeverity = useCallback(
    (key: string, isSeverity: boolean) => {
      if (isSeverity) {
        setFilters({ severity: { value: [key] } });
      } else {
        setFilters({ priority: { value: [key] } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onClickClusterReportingStatus = useCallback(
    (key: ReportStatus) => {
      setFilters({ reportStatus: { value: [key] } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (state?.isError404) {
    return <ErrorPage />;
  }

  return (
    <DashboardView>
      <DashboardCharts
        tiles={tiles}
        doughnutsCharts={
          <>
            <ClusterReportingStatus
              showSkeleton={showSkeleton}
              total={allData?.totalClusters}
              reportsReceived={allData?.reportsReceived}
              reportsMissing={allData?.reportsMissing}
              reportsPartial={allData?.reportsPartial}
              onClick={onClickClusterReportingStatus}
            />
            {
              <FailedStatusBy
                onClick={onClickFailedStatusBySeverity}
                data={allData?.groupedData}
                showSkeleton={showSkeleton}
              />
            }
          </>
        }
        showSkeleton={showSkeleton}
      />
      <CheckSummaryClusters
        onClear={onClearHandler}
        filters={filters}
        clusters={clusters || {}}
      />
    </DashboardView>
  );
};

const HeaderRight = () => {
  const { date, setDate, setClusters } = useCheckSummary();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showApiError } = useToast();

  const showDialog = () => {
    setInputText('');
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const validateInput = (text: string) => {
    if (text.trim() === '') {
      return 'Cluster name cannot be empty.';
    } else if (/\s/.test(text)) {
      return 'Cluster name cannot contain spaces.';
    } else if (/[^a-zA-Z0-9-_]/.test(text)) {
      return 'Cluster name can only contain alphanumeric characters, dashes, and underscores.';
    } else if (/--|__/.test(text)) {
      return 'Cluster name cannot contain two or more consecutive dashes or underscores.';
    } else if (
      text.endsWith('-') ||
      text.endsWith('_') ||
      text.startsWith('-') ||
      text.startsWith('_')
    ) {
      return 'Cluster name cannot start/end with a dash or underscore.';
    }
    return '';
  };

  const debouncedCheck = debounce((text) => {
    if (text) {
      const validationError = validateInput(text);
      setErrorMessage(validationError);
    } else {
      setErrorMessage('');
    }
  }, 500);

  useEffect(() => {
    debouncedCheck(inputText);
    return () => {
      // Clean up on unmount
      debouncedCheck.cancel();
    };
  }, [inputText, debouncedCheck]);

  const handleCreate = async () => {
    setIsLoading(true);
    try {
      const clusterName = inputText;

      // Create cluster with the input Name
      const requestBody: ClusterCreateRequest = {
        cluster_name: clusterName,
      };
      const createdCluster = await createCluster(requestBody);

      if (createdCluster && createdCluster.id) {
        // Set clusters only when cluster is created successfully
        const fetchDashboard = getDashboard(
          getDate((date || new Date()).toJSON()),
        );
        const dashboardData = await fetchDashboard();
        setClusters(dashboardData);
        closeDialog();
      } else {
        console.error(
          'Cluster creation did not return a valid response:',
          createdCluster,
        );
      }
    } catch (error) {
      if (error instanceof ApiError) {
        const errorCode = error.status;
        if (errorCode === 409) {
          setErrorMessage(error.body.message);
        } else {
          showApiError(error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setErrorMessage('');
    setInputText('');
    closeDialog();
  };

  const isInputValid = !errorMessage && inputText.trim().length > 0;

  return (
    <div className={styles.date}>
      <div>
        <Calendar
          showIcon
          style={{ width: '10rem' }}
          inputClassName={styles.input}
          iconPos={'right'}
          icon={<Icon size={'1.5rem'} name="calendar" />}
          placeholder={'MM/DD/YYYY'}
          value={date}
          onChange={(e) => setDate(e?.value)}
        />
      </div>
      <div>
        <Button className={styles.createCluster} onClick={showDialog}>
          Create Cluster
        </Button>
      </div>
      <Dialog
        header={<div className={styles.header}>Creating Cluster</div>}
        visible={dialogVisible}
        modal
        onHide={closeDialog}
        className={styles.dialog}
        footer={
          <div className={styles.footerContainer}>
            {isLoading ? (
              <div className={styles.loadingContent}>
                <ProgressSpinner
                  style={{
                    width: '2.5rem',
                    height: '2.5rem',
                  }}
                />
                <span style={{ marginLeft: '0.5rem' }}>
                  Creating your cluster, please wait <LoadingDots />
                </span>
              </div>
            ) : (
              <>
                <button
                  onClick={handleCreate}
                  className={`p-button p-button-primary ${styles.createButton} ${!isInputValid ? styles.disabledButton : ''}`}
                  disabled={!isInputValid}
                >
                  Create
                </button>
                <button
                  onClick={handleCancel}
                  className={`p-button p-button-secondary ${styles.cancelButton}`}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        }
      >
        <div className={styles.inputContainer}>
          <label className={styles.label}>Enter Cluster Name:</label>
          <InputText
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Cluster Name"
            className={styles.inputText}
            validateOnly={true}
          />
          {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        </div>
      </Dialog>
    </div>
  );
};

export default {
  title: 'Check Summary',
  description: 'test',
  headerRightTemplate: <HeaderRight />,
  route: { Component: Dashboard, path: '/' },
  pageStyle: 'summary',
} as RoutePageData;
