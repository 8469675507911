import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Icon,
  ResultStatus,
  ResultStatusProps,
  usePageContext,
} from 'components';
import { RoutePageData } from 'router/shared/models';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ApiError, CheckStatus } from 'shared/api/client';
import { useLoader, useLoaderWithCondinion } from 'hooks';
import { getDashboardDataByCluster } from 'pages/dashboard/shared/utils';
import { DashboardCharts, DashboardView } from 'pages/dashboard/dashboard-view';
import {
  getClusterDetails,
  getDashboard,
} from 'pages/dashboard/shared/requests';
import { getChecks, getFailures, getReports } from '../shared/requests';
import { useCheckSummary } from 'pages/dashboard/context';
import { SummaryCluster } from 'pages/dashboard/shared/models';
import { DataListView, Data } from './data-list';
import cx from 'classnames';
import styles from '../styles.module.scss';
import { useCurrentCluster } from './context';
import {
  ClusterCheckModel,
  ClusterReportModel,
  FailedCheckModel,
  TileID,
} from '../shared/models';
import { isEqual, toCapitalize } from 'shared/utils';
import { getDataTime, getDate } from 'components/utils';
import { ErrorPage } from 'pages/error-page';
import { countByStatus, getCurrentClusterTiles } from '../shared/utils';
import { FailedStatusBy } from 'pages/dashboard/dashboard-view/failed-status-by-severity.chart';
import { OverallByStatus } from '../../dashboard/dashboard-view/overall-by-status.chart';
import { FilterMeta } from 'components/filter-tags';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import {
  downloadStringTemplate,
  processConfigData,
} from 'shared/utils/fileUtils';
import { Dropdown } from 'primereact/dropdown';
import 'primeicons/primeicons.css';
import StatusIndicator from 'components/status-indicator';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronUpIcon } from 'primereact/icons/chevronup';

interface StateProps {}
type Params = {
  clusterId: string;
};

export const Cluster = () => {
  const { state, setState, error404 } = usePageContext<StateProps>();
  const paramsUrl = useParams<Params>();
  const [searchValue, setSearchValue] = useState('');
  const clusterId = paramsUrl?.clusterId || '';
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<FilterMeta>({});
  const {
    date: executionDate,
    clusters,
    setClusters,
    getCluster,
  } = useCheckSummary();

  const {
    failures,
    checks,
    reports,
    view,
    setView,
    setChecks,
    setFailures,
    setReports,
  } = useCurrentCluster();
  const cluster = useMemo(
    () => getCluster(paramsUrl?.clusterId || ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusters],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataRequest = useCallback(
    getDashboard(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataChecksRequest = useCallback(
    getChecks(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataReportsRequest = useCallback(getReports, [paramsUrl]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataFailuresRequest = useCallback(
    getFailures(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );

  const fetchDataCondition = useMemo(
    () =>
      !cluster?.clusterId ||
      !isEqual(
        cluster?.dateBy,
        getDate((executionDate || new Date())?.toJSON()),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsUrl, cluster, executionDate],
  );

  const fetchDataRequestErrorHandler = useCallback(
    (e: ApiError) => {
      if (e.status === 404) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        error404();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsUrl],
  );

  const { loadedData, isPending } = useLoaderWithCondinion(
    fetchDataRequest,
    fetchDataCondition,
    fetchDataRequestErrorHandler,
  );

  const { loadedData: loadedChecks, isPending: isPendingChecks } = useLoader(
    fetchDataChecksRequest,
  );

  const { loadedData: loadedReports, isPending: isPendingReports } = useLoader(
    fetchDataReportsRequest,
  );

  const { loadedData: loadedFailures, isPending: isPendingFailures } =
    useLoader(fetchDataFailuresRequest);

  useEffect(() => {
    if (paramsUrl?.clusterId && !isPendingReports) {
      const key = paramsUrl?.clusterId;
      setReports((prev) => ({
        ...prev,
        [key]: loadedReports || {},
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedReports, isPendingReports]);

  useEffect(() => {
    if (paramsUrl?.clusterId && !isPendingFailures) {
      const key = paramsUrl?.clusterId;
      setFailures((prev) => ({ ...prev, [key]: loadedFailures || {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedFailures, isPendingFailures]);

  useEffect(() => {
    if (paramsUrl?.clusterId && !isPendingChecks) {
      const key = paramsUrl?.clusterId;
      setChecks((prev) => ({ ...prev, [key]: loadedChecks || {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedChecks, isPendingChecks]);

  useEffect(() => {
    if (loadedData && !isPending) {
      setClusters(loadedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedData, isPending]);

  const onClickReport =
    (id: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      navigate(`/cluster/${paramsUrl?.clusterId}/reports/${id}`);
    };

  const onClickCheck =
    (opt: ClusterCheckModel) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const checkName = opt?.name;
      // setSearchValue(checkName);
      setFilters({ title: { value: [checkName] } });

      setView('failures');
    };

  const viewListData = useMemo(() => {
    switch (view) {
      case 'checks': {
        return {
          id: 'checks',
          loading: isPendingChecks,
          list: (Object.values((checks || {})[clusterId] || {}) || []).sort(
            (a, b) => b.count - a.count,
          ),
          header: (
            <div className={styles.dataHeader}>
              <div
                style={{ display: 'flex', flexGrow: 1, paddingLeft: '0.8rem' }}
              >
                Check
              </div>
              <div style={{ display: 'flex', width: '6rem' }}>Status</div>
            </div>
          ),
          searchFields: ['name'],
          itemTemplate: (opt, scollOptions) => {
            return (
              <div
                onClick={opt?.status !== 'PASS' ? onClickCheck(opt) : undefined}
                className={cx(styles.itemList, {
                  [styles.even]: scollOptions.even,
                  [styles.link]: opt?.status !== 'PASS',
                })}
                style={{ height: scollOptions?.props?.itemSize + 'px' }}
              >
                <h6 className={cx(styles.name)}>{opt.name}</h6>
                <div style={{ minWidth: '3.5rem' }} className={styles.data}>
                  <div className={styles.item}>
                    <Icon
                      className={cx(
                        styles.icon,
                        styles[opt?.status?.toLowerCase()],
                      )}
                      size={'1.5rem'}
                      name={opt?.status?.toLowerCase()}
                    />

                    {`${opt?.count || ''}`}
                  </div>
                </div>
              </div>
            );
          },
        } as Data<ClusterCheckModel>;
      }
      case 'reports': {
        return {
          id: 'reports',
          loading: isPendingReports,
          header: (
            <div className={styles.dataHeader}>
              <div
                style={{ display: 'flex', flexGrow: 1, paddingLeft: '0.8rem' }}
              >
                Report Name
              </div>
              <div style={{ display: 'flex', width: '10rem' }}>Date</div>
            </div>
          ),
          list: Object.values((reports || {})[clusterId] || {})?.sort(
            (a, b) => {
              const dateA = new Date(a.date).getTime();
              const dateB = new Date(b.date).getTime();
              return dateB - dateA;
            },
          ),
          searchFields: ['name'],
          itemTemplate: (opt, scollOptions) => {
            return (
              <div
                onClick={onClickReport(opt.id)}
                className={cx(styles.itemList, styles.link, {
                  [styles.even]: scollOptions.even,
                })}
                style={{ height: scollOptions?.props?.itemSize + 'px' }}
              >
                <h6 className={styles.name}>{opt.name}</h6>
                <div className={styles.data}>
                  <div className={styles.item}>{getDataTime(opt?.date)}</div>
                </div>
              </div>
            );
          },
        } as Data<ClusterReportModel>;
      }
      case 'failures': {
        return {
          id: 'failures',
          loading: isPendingFailures,
          list: Object.values((failures || {})[clusterId] || {}),
          itemSize: 85,
          header: (
            <div className={styles.dataHeader}>
              <div
                style={{ display: 'flex', flexGrow: 1, paddingLeft: '0.8rem' }}
              >
                Object/Check
              </div>
              <div style={{ display: 'flex', width: '7rem' }}>Result</div>
            </div>
          ),
          searchFields: ['name', 'title'],
          itemTemplate: (opt, scollOptions) => {
            return (
              <div
                className={cx(styles.itemList, {
                  [styles.even]: scollOptions.even,
                })}
                style={{ height: scollOptions?.props?.itemSize + 'px' }}
              >
                <div className={styles.titles}>
                  <h6 className={styles.name}>{opt.name}</h6>
                  <h6 className={styles.checkName}>{opt.title}</h6>
                </div>
                <div style={{ width: '6rem' }} className={styles.data}>
                  <div className={styles.item}>
                    <ResultStatus
                      type={opt?.status as ResultStatusProps['type']}
                    >
                      {toCapitalize(opt?.status)}
                    </ResultStatus>
                  </div>
                </div>
              </div>
            );
          },
        } as Data<FailedCheckModel>;
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { list: [], itemTemplate: () => <></> } as any;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, checks, reports, failures]);

  const allData = useMemo(
    () => getDashboardDataByCluster((cluster || {}) as SummaryCluster),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cluster],
  );

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        title: allData?.name,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  useEffect(
    () => () => {
      setState((prev) => {
        return {
          ...prev,
          title: undefined,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const showSkeleton = Object.keys(clusters || {}).length === 0 && isPending;

  const onClickTileHandler = (tile: TileID) => {
    setView('checks');
    setSearchValue('');
    switch (tile) {
      case 'total': {
        setFilters({});
        break;
      }
      case 'pass': {
        setFilters({ status: { value: ['PASS'] } });
        break;
      }
      case 'fail': {
        setFilters({ status: { value: ['FAIL'] } });
        break;
      }
      case 'noResult': {
        setFilters({ status: { value: ['ERROR', 'IGNORE'] } });
        break;
      }
      default: {
        break;
      }
    }
  };

  const tiles = useMemo(
    () => getCurrentClusterTiles(checks[clusterId] || {}, onClickTileHandler),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checks[clusterId], onClickTileHandler],
  );

  const onClearHandler = (key: keyof ClusterCheckModel, value: string) => {
    setFilters((prev) => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });
  };

  const onClearAllHandler = () => {
    setFilters({});
  };

  const onClickFailedStatusBySeverity = useCallback(
    (key: string, isSeverity: boolean) => {
      setView('checks');
      if (isSeverity) {
        setFilters({ severity: { value: [key] } });
      } else {
        setFilters({ priority: { value: [key] } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onClickOverallByStatus = useCallback((key: CheckStatus) => {
    setView('checks');
    if (key === 'ERROR') {
      setFilters({ status: { value: ['ERROR', 'IGNORE'] } });
    } else {
      setFilters({ status: { value: [key] } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state?.isError404) {
    return <ErrorPage />;
  }

  const checkList = Object.values((checks || {})[clusterId] || {});

  const totalChecks = checkList.length || 0;

  const counts = countByStatus(checkList);

  return (
    <DashboardView>
      <DashboardCharts
        clusterID={paramsUrl?.clusterId}
        tiles={tiles}
        doughnutsCharts={
          <>
            <OverallByStatus
              showSkeleton={showSkeleton}
              onClick={onClickOverallByStatus}
              total={totalChecks}
              passed={counts?.PASS}
              failed={counts?.FAIL}
              noResult={counts?.ERROR + counts?.UNKNOWN}
            />
            <FailedStatusBy
              data={allData?.groupedData}
              onClick={onClickFailedStatusBySeverity}
              showSkeleton={showSkeleton}
            />
          </>
        }
        showSkeleton={showSkeleton}
      />
      <DataListView
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onClear={onClearHandler}
        onClearAll={onClearAllHandler}
        filters={filters}
        data={viewListData || {}}
      />
    </DashboardView>
  );
};

const HeaderRight = () => {
  const { date, setDate, clusters } = useCheckSummary();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('');
  const [terminalWindow, setTerminalWindow] = useState<Window | null>(null);
  const [isTerminalConnected, setIsTerminalConnected] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const paramsUrl = useParams<Params>();
  const clusterId = paramsUrl?.clusterId || '';
  const clusterName = clusters ? clusters[clusterId]?.name : null;
  const [configExists, setConfigExists] = useState(false); // Check cluster config existence
  const [isConnectDisabled, setIsConnectDisabled] = useState(true); // Control Connect option
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false); // Control Download option
  const isMounted = useRef(true); // Track whether the component is mounted
  const [showDisconnectedTag, setShowDisconnectedTag] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Update state when dropdown is clicked (open/close)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node; // Assert type as Node
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Memoize closeTerminal function. This prevents unnecessary re-renders of components that use this function.
  const closeTerminal = useCallback(() => {
    if (terminalWindow) {
      terminalWindow.close();
      setTerminalWindow(null);
      setSelectedOption('');
      setIsDropdownOpen(false);
    }
  }, [terminalWindow]);

  // Memoize checkTerminalStatus function to ensure the function reference remains stable across renders.
  // The function is recreated only if isTerminalOpen or closeTerminal changes.
  const checkTerminalStatus = useCallback(
    async (isWindowActive: boolean) => {
      try {
        const clusterDetails = await getClusterDetails({ clusterId });

        if (clusterDetails.connection_status === 'CONNECTED') {
          setIsTerminalConnected(true);
          setShowDisconnectedTag(false);
          setIsDropdownOpen(false);
          // Only set isConnectDisabled to false if the terminal window is open
          if (
            (terminalWindow && !terminalWindow.closed) ||
            isWindowActive === true
          ) {
            setIsConnectDisabled(true);
          } else {
            setIsConnectDisabled(false);
          }
          setIsDownloadDisabled(true);
        } else {
          if (terminalWindow) {
            closeTerminal();
          }
          setIsTerminalConnected(false);
          setIsConnectDisabled(true);
          setIsDownloadDisabled(false);
          setShowDisconnectedTag(true);
        }
      } catch (error) {
        if (terminalWindow) {
          closeTerminal();
        }
        setIsTerminalConnected(false);
        setShowDisconnectedTag(true);
        setIsConnectDisabled(true);
        setIsDownloadDisabled(false);
      }
    },
    [terminalWindow, clusterId, closeTerminal],
  );

  const openTerminal = () => {
    const features =
      'toolbar=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600,left=200,top=200';

    const protocol = window.location.protocol;
    const host = window.location.host;
    const terminalUrl = `${protocol}//${host}${location.pathname}/terminal`;

    const newWindow = window.open(terminalUrl, '_blank', features);
    if (newWindow) {
      setTerminalWindow(newWindow);
      setSelectedOption('');
      // Check the terminal status after opening
      setTimeout(() => checkTerminalStatus(!newWindow.closed), 1000); // Delay to ensure terminal is fully opened
    }
  };

  const handleDropdownChange = (value: string) => {
    if (value === 'connect') {
      if (!terminalWindow) {
        openTerminal();
      }
    } else {
      setDialogVisible(true);
    }
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const handleOkay = () => {
    // Initiates the file download
    downloadFile();
    setSelectedOption('');
    closeDialog();
  };

  const handleCancel = () => {
    setSelectedOption('');
    closeDialog();
  };

  const downloadFile = async () => {
    try {
      console.log(window.origin);
      const apiUrl = `${window.origin}/v1alpha1/vpn/clients/config/${clusterName}`;
      const response = await axios.get(apiUrl, { responseType: 'text' });
      if (response.status === 200) {
        const processedData = processConfigData(response.data);

        const fileName = `${clusterName}.ovpn`;

        // Call downloadStringTemplate to trigger the download
        downloadStringTemplate(processedData, fileName);
      } else {
        console.error(`Failed to fetch file: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error fetching the file:', error);
    }
  };

  useEffect(() => {
    if (!configExists && !terminalWindow) {
      return; // Exit early if configExists is false and terminal is closed
    }

    isMounted.current = true;
    let timeoutId: NodeJS.Timeout | null = null; // Ref to store timeout ID
    let checkWindowId: NodeJS.Timeout | null = null; // Ref to store window check ID

    const checkStatusAndScheduleNext = async () => {
      try {
        // Determine if terminalWindow is null and set the isWindowActive flag accordingly
        const isWindowActive = terminalWindow !== null;
        await checkTerminalStatus(isWindowActive);
      } catch (error) {
        console.error('Error checking terminal status:', error);
      }

      if (isMounted.current) {
        timeoutId = setTimeout(checkStatusAndScheduleNext, 10000); // Schedule the next check after 10 seconds
      }
    };

    const checkWindowStatus = () => {
      if (!terminalWindow || terminalWindow.closed) {
        closeTerminal(); // Close and cleanup if the terminal window is closed
        setIsConnectDisabled(true); // Disable the connect button
      }
    };

    checkStatusAndScheduleNext(); // Start the first check

    if (terminalWindow) {
      checkWindowId = setInterval(checkWindowStatus, 1000); // Check window status every second
    }

    return () => {
      isMounted.current = false;
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear the timeout on unmount
      }
      if (checkWindowId) {
        clearInterval(checkWindowId); // Clear the interval on unmount
      }
    };
  }, [
    clusterName,
    configExists,
    terminalWindow,
    checkTerminalStatus,
    closeTerminal,
  ]); // Dependencies to re-run the effect

  useEffect(() => {
    const checkConfigExists = async () => {
      if (clusterName) {
        try {
          const apiUrl = `${window.origin}/v1alpha1/vpn/clients/config/${clusterName}`;
          const response = await axios.head(apiUrl); // Use HEAD method to check existence
          setConfigExists(response.status === 200);
        } catch (error) {
          setConfigExists(false);
        }
      }
    };

    checkConfigExists();
  }, [clusterName]);

  const dropdownOptions = [
    { label: 'Connect', value: 'connect', disabled: isConnectDisabled },
    {
      label: 'Download Configuration',
      value: 'download',
      disabled: isDownloadDisabled,
    },
  ];

  const RTS_ENABLED = window.__RUNTIME_CONFIG__.RTS_ENABLED;

  return (
    <div className={styles.date}>
      <div className={styles.outerContainer}>
        {RTS_ENABLED === 'true' && configExists && isTerminalConnected && (
          <StatusIndicator color="#57AE60" statusText="Online" title="Online" />
        )}
        {RTS_ENABLED === 'true' && configExists && showDisconnectedTag && (
          <StatusIndicator
            color="#717E90"
            statusText="Offline"
            title="Offline"
          />
        )}
        {RTS_ENABLED === 'true' && (
          <div
            ref={dropdownRef}
            className={cx(styles.clusterConfigDropdownContainer, {
              [styles.open]: isDropdownOpen,
            })}
          >
            <Dropdown
              style={{ minWidth: '15.5rem' }}
              value={selectedOption}
              onChange={(e) => configExists && handleDropdownChange(e.value)}
              options={dropdownOptions}
              optionLabel="label"
              placeholder="Select an option"
              className={styles.clusterConfigDropdown}
              disabled={!configExists}
              dropdownIcon={() => {
                const iconStyle = {
                  color: isDropdownOpen ? '#F25844' : 'none',
                };
                return isDropdownOpen ? (
                  <ChevronUpIcon style={iconStyle} />
                ) : (
                  <ChevronDownIcon style={iconStyle} />
                );
              }}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            />
          </div>
        )}
        <Calendar
          showIcon
          style={{ width: '9rem' }}
          inputClassName={styles.input}
          iconPos={'right'}
          icon={<Icon size={'1.5rem'} name="calendar" />}
          placeholder={'MM/DD/YYYY'}
          value={date}
          onChange={(e) => setDate(e?.value)}
        />
      </div>
      <Dialog
        visible={dialogVisible}
        header="Download File"
        modal
        footer={
          <div className="p-dialog-footer">
            <button
              onClick={handleOkay}
              className={`p-button p-button-primary ${styles.okayButton}`}
            >
              Okay
            </button>
            <button
              onClick={handleCancel}
              className={`p-button p-button-secondary ${styles.cancelButton}`}
            >
              Cancel
            </button>
          </div>
        }
        onHide={closeDialog}
      >
        <p>
          Click &quot;Okay&quot; to download the configuration file for cluster
          <strong> &apos;{clusterName}&apos;</strong> or &quot;Cancel&quot; to
          abort.
        </p>
      </Dialog>
    </div>
  );
};

const TitlePage = () => {
  const paramsUrl = useParams<Params>();

  const clusterId = paramsUrl?.clusterId || '';
  const { clusters, getCluster } = useCheckSummary();

  const cluster = useMemo(
    () => getCluster(clusterId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusters],
  );
  const match = useMatch(`/cluster/:clusterId/reports/:executionId`);

  return match ? <>{cluster?.name || 'Cluster'}</> : <>{'Cluster'}</>;
};

export default {
  title: <TitlePage />,
  showHomePageInBreadcrumbs: true,
  dynamicTitle: true,
  headerRightTemplate: <HeaderRight />,
  pageStyle: 'summary',
  route: {
    path: '/cluster/:clusterId',
    Component: Cluster,
  },
} as RoutePageData;
